.service {
  margin-bottom: 40px;
}

.service h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #5c3d2e;
}

.service p {
  font-size: 1rem;
  line-height: 1.6;
  color: #5c3d2e;
}

.carousel-item img {
  max-height: 400px;
  object-fit: cover;
}

h2 {
  font-size: 2rem;
  color: #5c3d2e;
  margin-bottom: 40px;
}