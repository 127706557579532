html {
  background-color: #E3DAFF;
}

body {
  background-color: #E3DAFF;
  font-family: "Crimson Text";
  color: #331E1E;
}

body.home-layout {
  /*background: url('./home-background.jpg') no-repeat center center fixed; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.home-layout-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}


.home-logo {
  /* logo styles */
  margin-bottom: 20px;
}

.container {
  gap: 20px;
  padding: 20px;
  max-width: 800px;
}

header {
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.logo {
  max-width: 200px;
  padding-bottom: 20px;
}


.nav {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
}

.nav.contact-buttons {
  justify-content: flex-start;
  margin-bottom: 20px;
}

.nav-link {
  border-radius: 17px;
  background: #FFF;
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.10);
  color: #642020;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.nav-link.active, .nav-link:hover {
  background-color: #5c3d2e;
  color: #fff;
}

.image-block {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
}

.page-title {
  color: #642020;
  font-family: "Crimson Text";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  
}

.section-title {
  color: #642020;
  font-family: "Crimson Text";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 10px;
}

.body-copy {
  color: #331E1E;
  font-family: "Crimson Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  overflow: auto;
}

.body-copy a {
  color: #331E1E;
  font-family: "Crimson Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

img {
  border-radius: 8px;
}

.profile-image img {
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

footer {
  padding: 20px;
}

.carousel-panel {
  background-color: rgba(248, 249, 250, 0.3);
  padding: 20px;
  margin: 30px auto 0 auto;
  border-radius: 12px;
}

.profile-image img {
  float: right;
  margin-top: 0;
  margin-left: 30px;
  margin-bottom: 10px;
  width: 150px; /* adjust this to your desired width */
  height: auto; /* maintain aspect ratio */
}

.cta-container {
  text-align: center;
  padding: 2em;
  background-color: #f9f9f9; /* light background */
  border-radius: 10px;
}

.nav {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
}


.cta-button {
  border-radius: 30px;
  border: 0;
  color: #FFF;
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.10);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 1em 2em;
  margin-top: 1em;
  background-color: #642020; /* darker shade of primary color */
}



.image-gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* default to 2 columns for small mobiles */
  gap: 20px;
  max-width: 100%;
  margin: 0 auto;
}

@media (min-width: 600px) {
  .image-gallery {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for larger mobiles and small desktops */
  }
}

@media (min-width: 1200px) {
  .image-gallery {
    grid-template-columns: repeat(4, 1fr); /* 4 columns for larger desktops */
  }
}

.image-gallery img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}



.image-gallery img {
  transition: transform 0.2s;
}

.image-gallery img:hover {
  transform: scale(1.05);
} 

.modal-overlay .image {
  max-width: 85vw;
  max-height: 75vh;
  border-radius: 20px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
}

.modal-content {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  max-width: 95%;
  max-height: 95%;
}

.modal-overlay .close-button {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 22 px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #642020;
  color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.10);
}